import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// -----------------------------------------------------------

Logo.propTypes = {
	disabledLink: PropTypes.bool,
	sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
	const theme = useTheme();
	const PRIMARY_LIGHT = theme.palette.primary.light;
	const PRIMARY_MAIN = theme.palette.primary.main;
	const PRIMARY_DARK = theme.palette.primary.dark;
	const logo = (
		<Box sx={{ width: 40, height: 40, ...sx }}>
			<svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%"  viewBox="0 0 500.000000 500.000000" >
				<defs>
					<linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
						<stop offset="0%" stopColor={PRIMARY_DARK} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
					<linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
						<stop offset="0%" stopColor={PRIMARY_LIGHT} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
					<linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
						<stop offset="0%" stopColor={PRIMARY_LIGHT} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
				</defs>
				<g
					fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd"  transform="translate(0.000000,500.000000) scale(0.100000,-0.100000)" >
<path d="M2545 3538 c-24 -13 -85 -69 -85 -78 0 -3 16 -6 35 -7 20 -1 42 -6
50 -13 16 -14 95 -147 95 -161 0 -5 -39 -27 -86 -50 -121 -57 -123 -80 -9
-114 59 -17 85 -31 85 -44 0 -3 -18 -26 -40 -50 -29 -32 -39 -50 -34 -65 4
-15 3 -17 -4 -7 -7 10 -15 5 -36 -21 -29 -39 -33 -53 -11 -44 8 3 15 0 15 -5
0 -7 -11 -9 -25 -7 -32 7 -60 -21 -40 -39 10 -9 14 -9 18 1 8 20 67 27 67 8 0
-8 6 -12 13 -9 21 8 13 -71 -9 -89 -15 -11 -15 -13 -3 -8 9 3 31 26 50 52 18
26 39 53 46 60 6 7 33 39 59 72 26 33 52 60 59 60 6 0 18 -12 26 -27 15 -32
69 -105 83 -115 6 -4 13 -5 17 -2 6 3 79 -110 79 -123 0 -3 15 -31 34 -62 91
-152 156 -290 156 -330 0 -49 -31 -76 -74 -65 -29 7 -31 9 -116 140 -95 146
-190 224 -273 224 -40 0 -140 -40 -153 -61 -5 -8 -1 -8 14 0 11 6 31 11 43 11
21 0 21 -1 5 -19 -17 -19 -17 -20 6 -24 17 -3 15 -5 -12 -7 -34 -3 -34 -3 10
-10 l45 -7 -40 -2 c-22 -1 -47 -5 -54 -10 -10 -6 -1 -8 25 -4 27 3 51 0 71
-10 47 -25 124 -115 182 -215 89 -151 145 -196 246 -196 165 -2 291 150 258
308 -7 29 -46 115 -94 201 -45 83 -132 243 -194 356 l-112 207 9 58 c12 75 6
163 -13 175 -13 9 -50 7 -96 -7 -18 -5 -25 1 -47 44 -51 100 -105 142 -183
142 -21 0 -47 -6 -58 -12z m5 -612 c0 -2 -7 -7 -16 -10 -8 -3 -12 -2 -9 4 6
10 25 14 25 6z m347 -28 c-3 -8 -6 -5 -6 6 -1 11 2 17 5 13 3 -3 4 -12 1 -19z
m-340 -25 c-3 -10 -5 -4 -5 12 0 17 2 24 5 18 2 -7 2 -21 0 -30z m-86 -26
c-10 -9 -11 -8 -5 6 3 10 9 15 12 12 3 -3 0 -11 -7 -18z m111 -39 c-7 -7 -12
-8 -12 -2 0 6 3 14 7 17 3 4 9 5 12 2 2 -3 -1 -11 -7 -17z"/>
<path d="M2015 3010 c-3 -5 -1 -10 4 -10 6 0 11 5 11 10 0 6 -2 10 -4 10 -3 0
-8 -4 -11 -10z"/>
<path d="M1968 2932 c-14 -26 -57 -103 -97 -172 -39 -69 -76 -141 -82 -160
-14 -48 -23 -153 -15 -183 5 -23 76 -107 90 -107 2 0 5 17 4 39 0 38 25 90
213 447 60 113 78 161 34 89 -13 -22 -25 -34 -25 -27 0 28 -98 48 -117 23 -19
-25 -15 1 8 48 28 59 19 61 -13 3z m99 -98 c-3 -3 -12 -4 -19 -1 -8 3 -5 6 6
6 11 1 17 -2 13 -5z"/>
<path d="M2410 2799 c0 -5 5 -7 10 -4 6 3 10 8 10 11 0 2 -4 4 -10 4 -5 0 -10
-5 -10 -11z"/>
<path d="M2381 2767 c-13 -21 -12 -21 5 -5 10 10 16 20 13 22 -3 3 -11 -5 -18
-17z"/>
<path d="M2505 2779 c-4 -6 -4 -13 -1 -16 3 -4 6 -1 6 5 0 7 5 12 11 12 5 0 7
-5 3 -12 -4 -7 -3 -8 5 -4 12 8 6 26 -9 26 -5 0 -12 -5 -15 -11z"/>
<path d="M2490 2740 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"/>
<path d="M2447 2643 c-4 -3 -7 -11 -7 -17 0 -6 5 -5 12 2 6 6 9 14 7 17 -3 3
-9 2 -12 -2z"/>
<path d="M1685 1605 l0 -165 128 0 127 0 0 40 0 39 -72 3 -73 3 -3 123 -3 122
-52 0 -52 0 0 -165z"/>
<path d="M2040 1605 l0 -166 53 3 52 3 0 160 0 160 -52 3 -53 3 0 -166z"/>
<path d="M2260 1730 l0 -40 70 0 c39 0 70 -3 70 -6 0 -6 -67 -88 -117 -144
-15 -16 -23 -37 -23 -62 l0 -38 148 0 148 0 -4 40 -4 40 -67 0 c-37 0 -71 3
-74 6 -5 6 57 86 111 142 22 24 32 50 29 74 -4 23 -6 23 -145 26 l-142 3 0
-41z"/>
<path d="M2646 1733 l1 -38 67 -3 c36 -2 66 -7 66 -13 0 -6 -31 -47 -70 -91
-57 -66 -70 -87 -70 -114 l0 -34 150 0 150 0 0 40 0 40 -75 0 c-74 0 -87 6
-62 32 7 7 38 44 70 81 45 54 57 75 57 103 l0 34 -142 0 -143 0 1 -37z"/>
<path d="M3009 1751 c6 -10 33 -55 61 -100 46 -77 50 -87 50 -147 l0 -64 55 0
55 0 0 63 c0 58 4 68 60 162 l60 100 -47 3 c-41 3 -49 0 -69 -25 -13 -15 -28
-40 -35 -55 -6 -16 -15 -28 -19 -28 -4 0 -20 25 -36 55 l-29 55 -58 0 c-53 0
-57 -1 -48 -19z"/>
<path d="M2670 1230 l0 -60 29 4 c40 4 51 14 51 41 0 27 -34 53 -45 35 -4 -6
-1 -9 6 -8 14 3 24 -26 15 -47 -3 -8 -12 -12 -21 -9 -11 4 -15 20 -15 55 0 27
-4 49 -10 49 -6 0 -10 -27 -10 -60z"/>
<path d="M2266 1264 c-21 -20 -21 -21 14 -39 41 -21 39 -39 -2 -31 -31 5 -32
5 -14 -9 24 -18 38 -19 54 -3 20 20 13 39 -18 49 -36 13 -40 33 -5 27 28 -6
34 6 9 16 -21 8 -20 8 -38 -10z"/>
<path d="M2460 1221 c0 -27 5 -41 16 -45 9 -4 19 -4 22 -1 3 3 0 5 -6 5 -7 0
-12 16 -12 40 0 22 -4 40 -10 40 -5 0 -10 -18 -10 -39z"/>
<path d="M2518 1220 c-7 -43 -4 -59 8 -48 3 3 4 26 2 49 l-3 44 -7 -45z"/>
</g>
</svg>


		</Box >
	);
	if (disabledLink) {
		return <>{logo}</>;
	}

	return <RouterLink to="">{logo}</RouterLink>;
}
